import React from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { useParams } from 'react-router-dom';
import {
  FIND_BY_ID_PROFISSIONAL_SAUDE_VENDA,
  FIND_BY_ID_VENDA,
  FIND_PERFIL_PUBLICO_BY_VENDA_AND_PROFISSIONAL_SAUDE,
  FIND_PERFIL_PUBLICO_UNIDADE_BY_VENDA_ID,
} from '../../graphql/queryes';
import { withBaseLayout } from '../../layout/base-layout';
import styled from '@emotion/styled';
import { Tabs, Tab } from '../../components/tabs';

import { PerfilPublicoDadosGerais } from './tabs/dados-gerais';
import { PerfilPublicoCustomizacao } from './tabs/customizacao';
import { PerfilPublicoAtividade } from './tabs/atividade';
import {
  PerfilPublicoProvider,
  usePerfilPublicoContext,
} from './perfil-publico-context';
import { Fab } from '@material-ui/core';
import { SaveOutlined } from '@material-ui/icons';
import {
  CREATE_PERFIL_PUBLICO_BY_VENDA_AND_PROFISSIONAL_SAUDE,
  CREATE_PERFIL_PUBLICO_UNIDADE_BY_VENDA,
  UPDATE_PERFIL_PUBLICO,
  UPDATE_PERFIL_PUBLICO_FOTO_BANNER,
  UPDATE_PERFIL_PUBLICO_FOTO_PERFIL,
  UPDATE_PROFISSIONAL_SAUDE,
  UPDATE_UNIDADE,
} from '../../graphql/mutations';
import moment from 'moment';
import { removeTypename } from '../../utils/removeTypename';
import Number from '../../utils/number';
import Notification from '../../components/notification/Notification';
import Button from '../../components/button';
import { Link } from 'react-router-dom';
import { VISUALIZAR_VENDA } from '../../router/names';
import string from '../../utils/string';
import { isPerfilPublicoValid } from '../../utils/isPerfilPublicoValid';

const TABS = {
  DADOS_GERAIS: 'Dados gerais',
  CUSTOMIZACAO: 'Customização',
  ATIVIDADE: 'Atividade',
};

const FIELDS_TO_CHECK_BY_TAB = {
  [TABS.DADOS_GERAIS]: ['dadosPessoais', 'dadosProfissionais'],
  [TABS.CUSTOMIZACAO]: [
    'medidasSeguranca',
    'fotoPerfilId',
    'bannerId',
    'redesSociais',
  ],
  [TABS.ATIVIDADE]: ['especialidades'],
};

const PerfilPublico = withBaseLayout(
  ({ venda, perfilPublico, profissionalSaude, unidade, onSave }) => {
    const [activeTab, setActiveTab] = React.useState(TABS.DADOS_GERAIS);
    const vendaId = venda?.id;

    const handleTabChange = (tab) => {
      setActiveTab(tab);
    };

    const renderTabContent = () => {
      switch (activeTab) {
        case TABS.DADOS_GERAIS:
          return <PerfilPublicoDadosGerais vendaId={vendaId} />;
        case TABS.CUSTOMIZACAO:
          return <PerfilPublicoCustomizacao />;
        case TABS.ATIVIDADE:
          return <PerfilPublicoAtividade />;
        default:
          return null;
      }
    };

    if (!profissionalSaude && !unidade)
      throw new Error(
        'Perfil público deve ser de um profissional ou de uma unidade'
      );

    if (!!profissionalSaude && !!unidade)
      throw new Error(
        'Perfil público deve ser de um profissional ou de uma unidade, não de ambos'
      );

    const validData = isPerfilPublicoValid(perfilPublico);

    return (
      <PerfilPublicoProvider
        perfilPublico={perfilPublico}
        profissionalSaude={profissionalSaude}
        unidade={unidade}
        venda={venda}
      >
        <div>
          <Link to={VISUALIZAR_VENDA.replace(':vendaId', vendaId)}>
            <Button>Voltar para a venda</Button>
          </Link>
        </div>

        {!validData.valid && (
          <div
            style={{
              marginTop: 16,
              width: '100%',
              padding: 8,
              border: '1px solid red',
              backgroundColor: 'rgba(255, 0, 0, 0.05)',
              color: 'red',
              fontWeight: 'bold',
              borderRadius: 4,
            }}
          >
            Perfil público inválido
            <ul
              style={{
                color: '#000',
                fontWeight: 'normal',
              }}
            >
              {validData.messages.map((message, index) => (
                <li key={index}>{message}</li>
              ))}
            </ul>
          </div>
        )}

        <Container>
          <Tabs onChange={handleTabChange} value={activeTab}>
            {Object.values(TABS).map((tab) => (
              <Tab
                key={tab}
                value={tab}
                style={{
                  flex: 1,
                }}
              >
                <TabContent tab={tab} />
              </Tab>
            ))}
          </Tabs>

          <Content>{renderTabContent()}</Content>
          <SavePerfilPublicoFab onSave={onSave} />
        </Container>
      </PerfilPublicoProvider>
    );
  }
);

const TabContent = ({ tab }) => {
  const { errors } = usePerfilPublicoContext();

  const errorKeys = Object.keys(errors);
  const hasErrors = errorKeys.some((key) => {
    return FIELDS_TO_CHECK_BY_TAB[tab].includes(key);
  });

  return (
    <div>
      {tab}
      {hasErrors && (
        <span
          style={{
            color: 'red',
            fontWeight: 'bold',
            marginLeft: 4,
          }}
        >
          *
        </span>
      )}
    </div>
  );
};

const SavePerfilPublicoFab = ({ onSave }) => {
  const defaultNotification = {
    isOpen: false,
    variant: 'success',
    message: '',
  };

  const [notification, setNotification] = React.useState(defaultNotification);
  const [isMutating, setIsMutating] = React.useState(false);

  const {
    handleSubmit,
    profissionalSaude,
    perfilPublico,
    unidade,
    isError,
    venda,
  } = usePerfilPublicoContext();

  const vendaId = venda?.id;

  const createQuery = unidade
    ? CREATE_PERFIL_PUBLICO_UNIDADE_BY_VENDA
    : CREATE_PERFIL_PUBLICO_BY_VENDA_AND_PROFISSIONAL_SAUDE;

  const [mutate, { loading }] = useMutation(
    perfilPublico?.id ? UPDATE_PERFIL_PUBLICO : createQuery
  );

  const [editarUnidade] = useMutation(UPDATE_UNIDADE);
  const [updateFotoPerfil] = useMutation(UPDATE_PERFIL_PUBLICO_FOTO_PERFIL);
  const [updateBanner] = useMutation(UPDATE_PERFIL_PUBLICO_FOTO_BANNER);

  const getDadosUnidade = (data) => {
    if (!unidade) return null;

    const unidadeWithoutTypename = removeTypename(unidade);

    delete unidadeWithoutTypename?.dataVencimento;

    return {
      ...unidadeWithoutTypename,
      nome: data?.dadosPessoais?.razaoSocial,
      nomeFantasia: data?.dadosPessoais?.nome,
      codigoCnes: data?.dadosPessoais?.codigoCnes,
      telefonePrincipal: string.removeSpecialChars(
        data?.dadosPessoais?.telefone
      ),
      perfilPublico: true,
      rede: {
        ...unidadeWithoutTypename?.rede,
        paisAtuacao: unidadeWithoutTypename?.rede && {
          id: unidadeWithoutTypename?.rede?.paisAtuacao?.value,
        },
      },
      endereco: unidadeWithoutTypename?.endereco && {
        ...unidadeWithoutTypename.endereco,
        municipio: unidadeWithoutTypename.endereco.municipio && {
          id: unidadeWithoutTypename.endereco.municipio?.value,
        },
      },
    };
  };

  const onSubmit = async () => {
    if (loading || isMutating) return;

    handleSubmit(async (data) => {
      const dadosUnidade = getDadosUnidade(data);

      try {
        setIsMutating(true);

        if (dadosUnidade) {
          await editarUnidade({
            variables: {
              unidade: {
                id: unidade?.id,
                ...dadosUnidade,
              },
            },
          });
        }

        if (perfilPublico) {
          if (perfilPublico?.fotoPerfil !== data.fotoPerfilId) {
            await updateFotoPerfil({
              variables: {
                perfilPublicoId: perfilPublico.id,
                fotoPerfilId: data.fotoPerfilId,
              },
            });
          }

          if (perfilPublico?.banner !== data.bannerId) {
            await updateBanner({
              variables: {
                perfilPublicoId: perfilPublico.id,
                fotoBannerId: data.bannerId,
              },
            });
          }
        }

        await mutate({
          variables: {
            perfilPublico: {
              id: perfilPublico?.id,
              ativo: true,
              nome: data.dadosPessoais.nome,
              telefonePrincipal: data.dadosPessoais.telefone?.replaceAll(
                /\D/g,
                ''
              ),
              telefoneSecundario:
                data.dadosPessoais.telefoneSecundario?.replaceAll(/\D/g, ''),
              email: data.dadosPessoais.email,
              sobre: data.dadosPessoais.sobre,

              formacao: data.dadosProfissionais?.formacao,
              experiencia: data.dadosProfissionais?.experiencia,

              atendePresencial: !!data.funcionalidades.atendimentoPresencial,
              utilizaTelemedicina: !!data.funcionalidades.atendimentoOnline,
              atendeCrianca: !!data.funcionalidades.atendeCrianca,
              permiteBuscaProfissional: !!data.funcionalidades.perfilVisivel,
              permiteBuscaGoogle: !!data.funcionalidades.buscaGoogle,
              permiteProfissionalUnidade:
                !!data.funcionalidades.permiteProfissionalUnidade,
              perfilVitrine: !!data.funcionalidades.perfilVitrine,
              utilizaAgendamentoWhatsapp: !!data.funcionalidades.utilizaAgendamentoWhatsapp,
              numeroWhatsapp:
              !!data.funcionalidades?.utilizaAgendamentoWhatsapp?
              data.funcionalidades?.numeroWhatsapp.replaceAll(
                /\D/g,
                ''
              )
              :
              null,
              idiomas: data.idiomas?.map((idioma) => ({
                id: idioma.value,
              })),

              formasPagamento: data.meiosPagamento?.map((meioPagamento) => ({
                id: meioPagamento.value,
              })),

              facebook: data.redesSociais.facebook,
              instagram: data.redesSociais.instagram,
              linkedin: data.redesSociais.linkedin,
              twitter: data.redesSociais.twitter,

              corPersonalizada: data.color,

              horarioAtendimento: data.horarioAtendimento,
              medidasSeguranca: data.medidasSeguranca,

              ...(!perfilPublico?.id
                ? { fotoPerfil: data.fotoPerfilId, banner: data.bannerId }
                : {}),

              ...(unidade
                ? {
                    especialidades: perfilPublico?.especialidades?.map(
                      ({ especialidadeMedica }) => {
                        const especialidade = data?.especialidades?.find(
                          (especialidade) =>
                            especialidadeMedica.id === especialidade.id
                        );

                        const { valorOnline, valorPresencial } =
                          especialidade || {};

                        return {
                          especialidadeMedica: {
                            id: especialidadeMedica.id,
                          },
                          ativo: !!especialidade,
                          valorOnline: Number.currencyToFloat(valorOnline),
                          valorPresencial:
                            Number.currencyToFloat(valorPresencial),
                        };
                      }
                    ),
                  }
                : {
                    especialidades: data.especialidades?.map(
                      (especialidade) => ({
                        ativo: true,
                        especialidadeMedica: {
                          id: especialidade?.id,
                        },
                        valorOnline: Number.currencyToFloat(
                          especialidade?.valorOnline
                        ),
                        valorPresencial: Number.currencyToFloat(
                          especialidade?.valorPresencial
                        ),
                      })
                    ),
                  }),
            },
            venda: {
              id: vendaId,
            },
            profissionalSaudeId: profissionalSaude?.id,
          },
        });

        await onSave();

        setNotification({
          isOpen: true,
          variant: 'success',
          message: 'Perfil público salvo com sucesso.',
        });
      } catch (e) {
        setNotification({
          isOpen: true,
          variant: 'error',
          message: 'Erro ao salvar perfil público, tente novamente.',
        });
      } finally {
        setIsMutating(false);
      }
    });
  };

  return (
    <>
      <Notification
        close={() => {
          setNotification({
            ...defaultNotification,
          });
        }}
        reset={() => {
          setNotification({
            ...defaultNotification,
          });
        }}
        isOpen={notification.isOpen}
        variant={notification.variant}
        message={notification.message}
      />

      <Fab
        color="primary"
        style={{
          position: 'fixed',
          bottom: 24,
          right: 24,
        }}
        onClick={onSubmit}
        disabled={loading || isError || isMutating}
      >
        <SaveOutlined />
      </Fab>
    </>
  );
};

export const PerfilPublicoProfissionalVenda = () => {
  const params = useParams();

  const { vendaId, profissionalId } = params;

  const {
    data,
    isLoading,
    refetch: refetchPerfil,
  } = useQuery(FIND_PERFIL_PUBLICO_BY_VENDA_AND_PROFISSIONAL_SAUDE, {
    fetchPolicy: 'no-cache',
    variables: {
      venda: {
        id: vendaId,
      },
      profissionalSaudeId: profissionalId,
    },
  });

  const {
    data: getVenda,
    isLoadingVenda,
    refetch: refetchVenda,
  } = useQuery(FIND_BY_ID_VENDA, {
    fetchPolicy: 'no-cache',
    variables: {
      id: vendaId,
    },
  });

  const {
    data: getProfissionalSaude,
    loading: loadingProfissionalSaude,
    refetch: refetchProfissional,
  } = useQuery(FIND_BY_ID_PROFISSIONAL_SAUDE_VENDA, {
    fetchPolicy: 'no-cache',
    variables: {
      vendaId: vendaId,
      id: profissionalId,
    },
  });

  const profissionalSaude =
    getProfissionalSaude?.findByIdProfissionalSaudeDaVenda;
  const perfilPublico = data?.findPerfilPublicoByVendaAndProfissionalSaudeId;
  const venda = getVenda?.findByIdVenda;

  if (isLoading || loadingProfissionalSaude || isLoadingVenda) return null;

  return (
    <PerfilPublico
      perfilPublico={perfilPublico}
      venda={venda}
      profissionalSaude={profissionalSaude}
      onSave={() => {
        return Promise.all([
          refetchPerfil(),
          refetchVenda(),
          refetchProfissional(),
        ]);
      }}
    />
  );
};

export const PerfilPublicoUnidadeVenda = () => {
  const params = useParams();

  const { vendaId } = params;

  const {
    data: getVenda,
    loading: loadingVenda,
    refetch: refetchVenda,
  } = useQuery(FIND_BY_ID_VENDA, {
    fetchPolicy: 'no-cache',
    variables: {
      id: vendaId,
    },
  });

  const unidade = getVenda?.findByIdVenda?.unidade;

  const {
    data,
    isLoading,
    refetch: refetchPerfil,
  } = useQuery(FIND_PERFIL_PUBLICO_UNIDADE_BY_VENDA_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      vendaId: vendaId,
    },
  });

  const perfilPublico = data?.findPerfilPublicoUnidadeByVendaId;

  if (isLoading || loadingVenda) return null;

  return (
    <PerfilPublico
      perfilPublico={perfilPublico}
      venda={getVenda?.findByIdVenda}
      unidade={unidade}
      onSave={() => {
        return Promise.all([refetchPerfil(), refetchVenda()]);
      }}
    />
  );
};

const Container = styled.div`
  background-color: white;
  padding: 24px;
  margin-top: 24px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
`;

const Content = styled.div`
  margin-top: 16px;
`;
