import React from 'react';

import styled from '@emotion/styled';

import { Switch } from '@material-ui/core';
import { TextField, TextFieldSearch } from '../../../../components/TextField';
import { usePerfilPublicoContext } from '../../perfil-publico-context';
import {
  FIND_ALL_FORMA_PAGAMENTO,
  FIND_ALL_IDIOMA,
} from '../../../../graphql/queryes';
import { PerfilPublicoDadosPessoais } from './dados-pessoais';
import { PerfilDadosProfissionais } from './dados-profissionais';
import { useAsyncSelectPaginated } from '../../../../hooks/useAsyncSelectPaginated';
import FormControl from '../../../../components/FormControl';
import { PerfilDadosClinica } from './dados-clinica';
import { buildLinkPerfilPublico } from '../../../../utils/buildLinkPerfilPublico';
import { InputPhoneForm } from '../../../../components/input/InputPhoneForm';

export const PerfilPublicoDadosGerais = () => {
  const { setField, values, errors, unidade, perfilPublico, venda } =
    usePerfilPublicoContext();

  const handleSetFuncionalidades = (name, value) => {
    setField('funcionalidades.' + name, value);
  };

  const funcionalidadesErrors = errors.funcionalidades || {};

  const { loadOptions: loadIdiomas } = useAsyncSelectPaginated(
    FIND_ALL_IDIOMA,
    {
      variables: {
        searchDTO: {
          ativo: true,
        },
        pageableDTO: {
          pageNumber: 0,
          pageSize: 20,
        },
      },
      dataKey: 'findAllIdioma',
    }
  );

  const { loadOptions: loadMeiosPagamento } = useAsyncSelectPaginated(
    FIND_ALL_FORMA_PAGAMENTO,
    {
      variables: {
        searchDTO: {
          ativo: true,
        },
        pageableDTO: {
          pageNumber: 0,
          pageSize: 20,
        },
      },
      dataKey: 'findAllFormaPagamento',
    }
  );

  const funcionalidades = values.funcionalidades || {};

  return (
    <Container>
      <ContainerColumn>
        <PerfilPublicoDadosPessoais />
        {unidade ? <PerfilDadosClinica /> : <PerfilDadosProfissionais />}
      </ContainerColumn>

      <ContainerColumn>
        <Card>
          <h2>Link do perfil</h2>

          <div>
            <TextField
              label="Link"
              disabled
              value={
                perfilPublico
                  ? buildLinkPerfilPublico(
                      perfilPublico,
                      venda?.unidade?.rede?.whitelabel?.frontWebUrl
                    )
                  : 'Não disponível'
              }
            />
          </div>
        </Card>

        <Card>
          <h2>Ajuste as funcionalidades</h2>

          {unidade && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div>Exibir profissionais da unidade</div>
              <Switch
                color="primary"
                checked={funcionalidades.permiteProfissionalUnidade}
                value={funcionalidades.permiteProfissionalUnidade}
                onChange={(e) =>
                  handleSetFuncionalidades(
                    'permiteProfissionalUnidade',
                    !funcionalidades.permiteProfissionalUnidade
                  )
                }
              />
            </div>
          )}

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>Atendimento presencial</div>
            <Switch
              color="primary"
              checked={funcionalidades.atendimentoPresencial}
              value={funcionalidades.atendimentoPresencial}
              onChange={(e) =>
                handleSetFuncionalidades(
                  'atendimentoPresencial',
                  !funcionalidades.atendimentoPresencial
                )
              }
            />
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>Atendimento por teleconsulta</div>
            <Switch
              color="primary"
              checked={funcionalidades.atendimentoOnline}
              value={funcionalidades.atendimentoOnline}
              onChange={(e) =>
                handleSetFuncionalidades(
                  'atendimentoOnline',
                  !funcionalidades.atendimentoOnline
                )
              }
            />
          </div>

          {!unidade && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div>Atende crianças</div>
              <Switch
                color="primary"
                checked={funcionalidades.atendeCrianca}
                value={funcionalidades.atendeCrianca}
                onChange={(e) =>
                  handleSetFuncionalidades(
                    'atendeCrianca',
                    !funcionalidades.atendeCrianca
                  )
                }
              />
            </div>
          )}

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>Perfil visível na busca</div>
            <Switch
              color="primary"
              checked={funcionalidades.perfilVisivel}
              value={funcionalidades.perfilVisivel}
              onChange={(e) =>
                handleSetFuncionalidades(
                  'perfilVisivel',
                  !funcionalidades.perfilVisivel
                )
              }
            />
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>Exibir perfil nos resultados do google</div>
            <Switch
              color="primary"
              checked={funcionalidades.buscaGoogle}
              value={funcionalidades.buscaGoogle}
              onChange={(e) =>
                handleSetFuncionalidades(
                  'buscaGoogle',
                  !funcionalidades.buscaGoogle
                )
              }
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection:"column"
            }}
          >
            <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width:"100%"
            }}
          >
            <div>Utiliza agendamento por whatsapp</div>
            <Switch
              color="primary"
              checked={funcionalidades.utilizaAgendamentoWhatsapp}
              value={funcionalidades.utilizaAgendamentoWhatsapp}
              onChange={(e) =>
                handleSetFuncionalidades(
                  'utilizaAgendamentoWhatsapp',
                  !funcionalidades.utilizaAgendamentoWhatsapp
                )
              }
            />
            </div>
            {funcionalidades.utilizaAgendamentoWhatsapp &&<div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width:"100%"
            }}
          >
            <FormControl label="Telefone - whatsapp" error={funcionalidadesErrors.numeroWhatsapp}>
              <InputPhoneForm
                value={funcionalidades.numeroWhatsapp}
                onChange={(e) => handleSetFuncionalidades('numeroWhatsapp', e.target.value)}
              />
            </FormControl>
          </div>}
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>Habilitar perfil apenas para visualização</div>
            <Switch
              color="primary"
              checked={funcionalidades.perfilVitrine}
              value={funcionalidades.perfilVitrine}
              onChange={(e) =>
                handleSetFuncionalidades(
                  'perfilVitrine',
                  !funcionalidades.perfilVitrine
                )
              }
            />
          </div>
        </Card>

        <Card>
          <h2>Idiomas</h2>

          <FormControl error={errors.idiomas}>
            <TextFieldSearch
              withPaginate
              loadOptions={loadIdiomas}
              debounceTimeout={300}
              additional={{
                page: 0,
              }}
              isMulti
              getOptionLabel={(option) => option.descricao}
              getOptionValue={(option) => option.id}
              getValue={(option) => option.label}
              value={(values?.idiomas || [])?.map((idioma) => ({
                id: idioma.value,
                descricao: idioma.label,
              }))}
              onChange={(opt) => {
                setField(
                  'idiomas',
                  (opt || []).map((idioma) => ({
                    value: idioma.id,
                    label: idioma.descricao,
                  }))
                );
              }}
            />
          </FormControl>
        </Card>

        {!unidade && (
          <Card>
            <h2>Meios de pagamento disponíveis</h2>

            <FormControl error={errors.meiosPagamento}>
              <TextFieldSearch
                withPaginate
                loadOptions={loadMeiosPagamento}
                debounceTimeout={300}
                additional={{
                  page: 0,
                }}
                getOptionLabel={(option) => option.nome}
                getOptionValue={(option) => option.id}
                getValue={(option) => option.label}
                isMulti
                value={(values?.meiosPagamento || [])?.map((meioPagamento) => ({
                  id: meioPagamento.value,
                  nome: meioPagamento.label,
                }))}
                onChange={(opt) => {
                  setField(
                    'meiosPagamento',
                    (opt || []).map((meioPagamento) => ({
                      value: meioPagamento.id,
                      label: meioPagamento.nome,
                    }))
                  );
                }}
              />
            </FormControl>
          </Card>
        )}
      </ContainerColumn>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-gap: 24px;
  padding-bottom: 20px;
  margin-left: 0px;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 760px) {
    grid-template-columns: 1fr;
  }
`;

const ContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px;
`;
